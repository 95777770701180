.Exercise {
    position: relative;
    border: 1px solid #46464680;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    margin: 10px auto;
    border-radius: 4px;
    display: block;
    width: 100%;
    text-align: center;
    height: 104px;
    line-height: 104px;
    background-color: white;
    color: #464646;
    font-weight: bold;
}

.Exercise:hover {
    cursor: pointer;
    background-color: rgba(210, 237, 255, 0.924);
}

.TitleArea {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
}

.TitleArea h5 {
    /* letter-spacing: 1px; */
    /* margin: 10px 0; */
    /* line-height: 104px; */
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 60%;
    padding: 0 50px;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
}


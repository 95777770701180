.Back {
    position: relative;
    margin: auto;
}

@media (min-width: 500px) and (min-height: 400px) {
    .Back {
        width: 300px;
        border-radius: 10px;
        border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    }
}

@media (min-width: 500px) and (min-height: 400px) {
    .BackDark {
        border: 1px solid rgb(255, 255, 255);
        box-shadow: 0 10px 10px rgba(255, 255, 255, 0.52);
    }
}
/* .OrderButton:not(:disabled) {
  animation: enable 0.3s linear;
}

@keyframes enable {
  0% {
      transform: scale(1);
  }
  60% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
} */


.Button::-moz-focus-inner{
    border: 0;
    padding: 0;
  }
  
  .Button{
    display: inline-block;
    width: 80%;
    *display: inline;
    zoom: 1;
    padding: 4px;
    margin: 4px;
    cursor: pointer;
    border: 1px solid #bbb;
    overflow: visible;
    /* font: bold 13px arial, helvetica, sans-serif; */
    font: inherit;
    text-decoration: none;
    white-space: nowrap;
    color: #555;
    

    background-color: #ddd;
    /* background-image: -webkit-gradient(linear, from left top(rgba(255,255,255,1)), to left bottom(rgba(255,255,255,0))); */
    background-image: -webkit-linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: -moz-linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: -ms-linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: -o-linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    
    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -ms-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
    background-clip: padding-box; /* Fix bleeding */
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
    text-shadow: 0 1px 0 rgba(255,255,255, .9);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .Button:hover{
    background-color: #eee;
    color: #555;
  }
  
  .Button:active{
    background: #e9e9e9;
    position: relative;
    top: 1px;
    text-shadow: none;
    -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
  }
  
  .Button[disabled], .Button[disabled]:hover, .Button[disabled]:active{
    border-color: #eaeaea;
    background: #fafafa;
    cursor: default;
    position: static;
    color: #999;
    /* Usually, !important should be avoided but here it's really needed :) */
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  
  /* Smaller Buttons styles */
  
  .Button.Small{
    padding: 4px 12px;
  }
  
  /* Larger Buttons styles */
  
  .Button.Large{
    padding: 12px 30px;
    text-transform: uppercase;
  }
  
  .Button.Large:active{
    top: 2px;
  }
  
  /* ColoRed Buttons styles */
  
  .Button.Green, .Button.Red, .Button.Blue {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0,0,0,.2);
    
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,.3)), to(rgba(255,255,255,0))); */
    background-image: -webkit-linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,0));
    background-image: -moz-linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,0));
    background-image: -ms-linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,0));
    background-image: -o-linear-gradient(tto bottomop, rgba(255,255,255,.3), rgba(255,255,255,0));
    background-image: linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,0));
  }
  
  /* */
  
  .Button.Green{
    background-color: #00c353;
    border-color: #00c353;
  }
  
  .Button.Green:hover{
    background-color: #3ebb3e;
  }
  
  .Button.Green:active{
    background: #57a957;
  }
  
  /* */
  
  .Button.Red{
    background-color: #ca3535;
    border-color: #c43c35;
  }
  
  .Button.Red:hover{
    background-color: #ee5f5b;
  }
  
  .Button.Red:active{
    background: #c43c35;
  }
  
  /* old blue #269CE9 */
  
  .Button.Blue{
    background-color: #096070;
    border-color: #096070;
  }
  
  .Button.Blue:hover{
    background-color: #70B9E8;
  }
  
  .Button.Blue:active{
    background: #096070;
  }
  
  .Button.White{
    background-color: #7a7a7a;
    border-color: #ffffff;
    color: #ffffff;
    background-image: none;
  }
  
  .Button.White:hover{
    background-color: #bebebe;
    border-color: #bebebe;
    color: white;
  }
  
  .Button.White:active{
    /* background: #dbdbdb; */
  }

  /* */
  
  .Green[disabled], .Green[disabled]:hover, .Green[disabled]:active{
    border-color: #00c353;
    background: #00c353;
    color: #c2c2c2;
  }
  
  .Red[disabled], .Red[disabled]:hover, .Red[disabled]:active{
    border-color: #C43C35;
    background: #C43C35;
    color: #FFD3D3;
  }
  
  .Blue[disabled], .Blue[disabled]:hover, .Blue[disabled]:active{
    border-color: #096070;
    background: #096070;
    color: #aaafb3;
  }
  
  .White[disabled], .White[disabled]:hover, .White[disabled]:active{
    background: #dfdfdf;
    border-color: #dfdfdf;
    color: #5d5d5d;
  }
  
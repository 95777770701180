.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    border-radius: 4px;
    width: 90%;
    /* border: 1px solid #ccc; */
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 5%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.ModalDark {
    background-color: #464646;
    color: white;
}

@media (min-width: 650px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}
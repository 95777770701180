.Exercises {
    width: 100%;
    height: 100%;
    margin: auto;
}

.Title {
	/* letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center; */
}

.Subtitle {
    font-size: .9rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: center;
}

.Label {
    margin: 25px 0 0 0;
	letter-spacing: 1px;
    font-weight: bold;
    font-size: 1.1rem;
    text-align: center;
    opacity: .7;
}

.Sublabel {
    font-size: .6rem;
    margin: 0 0 5px 0;
    padding: 10px;
    opacity: 0.8;
    text-align: center;
}
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
body {
  margin: 0;
  padding: 0;
  /* background-color: #cee5e9; */
  /* background-color: lightgrey; */
  /* background-color: #464646; */
  /* background-color: white; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.AuthUI_AuthUI__3qQrI {
    width: 90%;
    margin: auto;
    padding: 20px 5px;
    text-align: center;
    /* font-weight: bold; */
    font-size: 1rem;
    align-items: center;

    /* background-color: white; */
}

@media (min-width: 500px) and (min-height: 400px) {
    .AuthUI_AuthUI__3qQrI {
        width: 300px;
        border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
}

.AuthUI_AuthUIDark__19_vI {
    color: white;
}

@media (min-width: 500px) and (min-height: 400px) {
    .AuthUI_AuthUIDark__19_vI {
        border: 1px solid rgb(255, 255, 255);
        box-shadow: 0 10px 10px #ffffff85;
    }
}
/* .OrderButton:not(:disabled) {
  animation: enable 0.3s linear;
}

@keyframes enable {
  0% {
      transform: scale(1);
  }
  60% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
} */


.Button_Button__2QtRd::-moz-focus-inner{
    border: 0;
    padding: 0;
  }
  
  .Button_Button__2QtRd{
    display: inline-block;
    width: 80%;
    *display: inline;
    zoom: 1;
    padding: 4px;
    margin: 4px;
    cursor: pointer;
    border: 1px solid #bbb;
    overflow: visible;
    /* font: bold 13px arial, helvetica, sans-serif; */
    font: inherit;
    text-decoration: none;
    white-space: nowrap;
    color: #555;
    

    background-color: #ddd;
    /* background-image: -webkit-gradient(linear, from left top(rgba(255,255,255,1)), to left bottom(rgba(255,255,255,0))); */
    background-image: linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0));
    transition: background-color .2s ease-out;
    background-clip: padding-box; /* Fix bleeding */
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .3), 0 2px 2px -1px rgba(0, 0, 0, .5), 0 1px 0 rgba(255, 255, 255, .3) inset;
    text-shadow: 0 1px 0 rgba(255,255,255, .9);
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .Button_Button__2QtRd:hover{
    background-color: #eee;
    color: #555;
  }
  
  .Button_Button__2QtRd:active{
    background: #e9e9e9;
    position: relative;
    top: 1px;
    text-shadow: none;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .3) inset;
  }
  
  .Button_Button__2QtRd[disabled], .Button_Button__2QtRd[disabled]:hover, .Button_Button__2QtRd[disabled]:active{
    border-color: #eaeaea;
    background: #fafafa;
    cursor: default;
    position: static;
    color: #999;
    /* Usually, !important should be avoided but here it's really needed :) */
    box-shadow: none !important;
    text-shadow: none !important;
  }
  
  /* Smaller Buttons styles */
  
  .Button_Button__2QtRd.Button_Small__2f0u4{
    padding: 4px 12px;
  }
  
  /* Larger Buttons styles */
  
  .Button_Button__2QtRd.Button_Large__3hJp2{
    padding: 12px 30px;
    text-transform: uppercase;
  }
  
  .Button_Button__2QtRd.Button_Large__3hJp2:active{
    top: 2px;
  }
  
  /* ColoRed Buttons styles */
  
  .Button_Button__2QtRd.Button_Green__2AQ0X, .Button_Button__2QtRd.Button_Red__1sU5u, .Button_Button__2QtRd.Button_Blue__3ZunV {
    color: #fff;
    text-shadow: 0 1px 0 rgba(0,0,0,.2);
    
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,.3)), to(rgba(255,255,255,0))); */
    background-image: linear-gradient(to bottom, rgba(255,255,255,.3), rgba(255,255,255,0));
  }
  
  /* */
  
  .Button_Button__2QtRd.Button_Green__2AQ0X{
    background-color: #00c353;
    border-color: #00c353;
  }
  
  .Button_Button__2QtRd.Button_Green__2AQ0X:hover{
    background-color: #3ebb3e;
  }
  
  .Button_Button__2QtRd.Button_Green__2AQ0X:active{
    background: #57a957;
  }
  
  /* */
  
  .Button_Button__2QtRd.Button_Red__1sU5u{
    background-color: #ca3535;
    border-color: #c43c35;
  }
  
  .Button_Button__2QtRd.Button_Red__1sU5u:hover{
    background-color: #ee5f5b;
  }
  
  .Button_Button__2QtRd.Button_Red__1sU5u:active{
    background: #c43c35;
  }
  
  /* old blue #269CE9 */
  
  .Button_Button__2QtRd.Button_Blue__3ZunV{
    background-color: #096070;
    border-color: #096070;
  }
  
  .Button_Button__2QtRd.Button_Blue__3ZunV:hover{
    background-color: #70B9E8;
  }
  
  .Button_Button__2QtRd.Button_Blue__3ZunV:active{
    background: #096070;
  }
  
  .Button_Button__2QtRd.Button_White__Nka5V{
    background-color: #7a7a7a;
    border-color: #ffffff;
    color: #ffffff;
    background-image: none;
  }
  
  .Button_Button__2QtRd.Button_White__Nka5V:hover{
    background-color: #bebebe;
    border-color: #bebebe;
    color: white;
  }
  
  .Button_Button__2QtRd.Button_White__Nka5V:active{
    /* background: #dbdbdb; */
  }

  /* */
  
  .Button_Green__2AQ0X[disabled], .Button_Green__2AQ0X[disabled]:hover, .Button_Green__2AQ0X[disabled]:active{
    border-color: #00c353;
    background: #00c353;
    color: #c2c2c2;
  }
  
  .Button_Red__1sU5u[disabled], .Button_Red__1sU5u[disabled]:hover, .Button_Red__1sU5u[disabled]:active{
    border-color: #C43C35;
    background: #C43C35;
    color: #FFD3D3;
  }
  
  .Button_Blue__3ZunV[disabled], .Button_Blue__3ZunV[disabled]:hover, .Button_Blue__3ZunV[disabled]:active{
    border-color: #096070;
    background: #096070;
    color: #aaafb3;
  }
  
  .Button_White__Nka5V[disabled], .Button_White__Nka5V[disabled]:hover, .Button_White__Nka5V[disabled]:active{
    background: #dfdfdf;
    border-color: #dfdfdf;
    color: #5d5d5d;
  }
  
.Spinner_Loader__LGH2Q {
  color: #096070;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  -webkit-animation: Spinner_load6__1bz-2 1.7s infinite ease, Spinner_round__12Tdb 1.7s infinite ease;
  animation: Spinner_load6__1bz-2 1.7s infinite ease, Spinner_round__12Tdb 1.7s infinite ease;
}
@-webkit-keyframes Spinner_load6__1bz-2 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes Spinner_load6__1bz-2 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes Spinner_round__12Tdb {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Spinner_round__12Tdb {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Spinner_LoaderDark__28qOV {
  color: #fff;
}
.Toolbar_Toolbar__wD-Po {
    height: 56px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(9, 96, 112);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}

.Toolbar_ToolbarDark__12SjL {
    background-color: rgb(51, 51, 51);
}

.Toolbar_Toolbar__wD-Po nav {
    height: 100%;
}

.Toolbar_Logo__1uufI {
    height: 80%;
}

@media (max-width: 499px) {
    .Toolbar_DesktopOnly__1-CB- {
        display: none;
    }
}
.Logo_Logo__2jlsJ {
    /* background-color: white; */
    padding: 3px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-flow: row;
}

.Logo_Logo__2jlsJ img {
    height: 100%;
}

.Logo_Logo__2jlsJ h6 {
    align-items: center;
    color: white; 
    line-height: 45px;
    font-size: 1.2rem;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
}


.NavigationItems_NavigationItems__-hPss {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

@media (min-width: 500px) {
    .NavigationItems_NavigationItems__-hPss {
        flex-flow: row;
    }
}
.NavigationItem_NavigationItem__2rKkc {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
}

.NavigationItem_NavigationItem__2rKkc a {
    color: #464646;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem_NavigationItem__2rKkc a:hover,
.NavigationItem_NavigationItem__2rKkc a:active,
.NavigationItem_NavigationItem__2rKkc a.NavigationItem_active__15U3B {
    color: #096070;
}

.NavigationItem_NavigationItemDark__3VVXu a:hover,
.NavigationItem_NavigationItemDark__3VVXu a:active,
.NavigationItem_NavigationItemDark__3VVXu a.NavigationItem_active__15U3B {
    color: #096070;
}

@media (min-width: 500px) {
    .NavigationItem_NavigationItem__2rKkc {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem_NavigationItem__2rKkc a {
        color: white;
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationItem_NavigationItem__2rKkc a:hover,
    .NavigationItem_NavigationItem__2rKkc a:active,
    .NavigationItem_NavigationItem__2rKkc a.NavigationItem_active__15U3B {
        /* background-color: #096070; */
        border-bottom:4px solid #40a4c8;
        color: white;
    }

    .NavigationItem_NavigationItemDark__3VVXu a:hover,
    .NavigationItem_NavigationItemDark__3VVXu a:active,
    .NavigationItem_NavigationItemDark__3VVXu a.NavigationItem_active__15U3B {
        border-bottom: 4px solid #cccccc;
        color: white;
    }
}
.DrawerToggle_DrawerToggle__2DbbL {
    width: 40px;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
}

.DrawerToggle_DrawerToggle__2DbbL div {
    width: 90%;
    height: 3px;
    background-color: white;
}

@media (min-width: 500px) {
    .DrawerToggle_DrawerToggle__2DbbL {
        display: none;
    }
}

.Layout_Content__1lArY {
    margin-top: 72px;
}
.SideDrawer_SideDrawer__3283D {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

@media (min-width: 500px) {
    .SideDrawer_SideDrawer__3283D {
        display: none;
    }
}

.SideDrawer_Open__33OMg {
    transform: translateX(0);
}

.SideDrawer_Close__2EIuJ {
    transform: translateX(-100%);
}

.SideDrawer_Logo__FiBnQ {
    height: 11%;
    margin-bottom: 32px;
}

.SideDrawer_InstallApp__2PBws {
    max-height: 150px;
    border-radius: 4px;
    width: 100%;
    background-color: rgb(88, 218, 163);
    color: white;
    align-items: center;
    text-align: center;
    padding: 10px;
    display: block;
    font-size: .9rem;
}

.SideDrawer_InstallButton__1kmWj {
    background-color: white;
    font-weight: bold;
    color:  rgb(88, 218, 163);
    border: none;
    padding: 4px;
    min-width: 75px;
    margin: 0 auto;
    border-radius: 20px;
    font-size: .75rem;
    
}

.SideDrawer_InstallDismiss__1_hrb {
    background-color: rgb(88, 218, 163); 
    color: #949494;
}

.SideDrawer_Divider__2bmtU {
    width:5px;
    height:auto;
    display:inline-block;
}
.Backdrop_Backdrop__3Vyh_ {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.85);
}
.Alert_Alert__3JGVI {
    position: fixed;
    width: 100%;
    height: 40px;
    left: 0;
    top: 56px;
    z-index: 200;
    /* background-color: #d4edda;
    color: #0f4111; */
    background-color: #00c353;
    color: #fdfdfd;
    line-height: 40px;
    font-weight: bold;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

.Alert_Show__tMBTw {
    transform: translateY(0);
}

.Alert_Hide__33btu {
    transform: translateY(-120px);
}


.Front_Front__18JQ3 {
    position: relative;
    margin: auto;
}

@media (min-width: 500px) and (min-height: 400px) {
    .Front_Front__18JQ3 {
        width: 300px;
        border-radius: 10px;
        border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    }
}

@media (min-width: 500px) and (min-height: 400px) {
    .Front_FrontDark__2bMhR {
        border: 1px solid rgb(255, 255, 255);
        box-shadow: 0 10px 10px rgba(255, 255, 255, 0.52);
    }
}
.Muscles_Muscle__1qoxW {
    cursor: pointer;
    /* fill: grey; */
}

.Muscles_Icon__zXt4B {
    fill: #464646;
    /* fill: white; */
} 

.Muscles_IconSelected__1hm1c {
    fill: yellowgreen;
    /* fill: yellow; */
}
.Pattern_Pattern__R58nF {
    height: 100%;
}

.Pattern_Pattern__R58nF path {
    fill: white;
    stroke-width: 90;
}

.Pattern_DarkGreen__1OYsM {
    stroke: rgb(93, 151, 93);
}

.Pattern_Green__25I_b {
    stroke: rgb(144, 207, 147);
}

.Pattern_Amber__1v14r {
    stroke: rgb(240, 205, 50);
}

.Pattern_DarkAmber__1tGpm {
    stroke: rgb(226, 134, 13);
}

.Pattern_Red__1_Jkf {
    stroke: rgb(156, 51, 51);
} 

.Pattern_NonClickable__fKCm_ {
    stroke: rgb(93, 151, 93);
}
.Key_Key__2WBVx {
    position: absolute;
    /* font-size: .8rem; */
    left: 5px;
    bottom: 5px;
    text-align: left;
    /* max-width: 160pxs */
    color: #096070;
}

.Key_KeyDark__387wG {
    color: white;
}

.Key_Key__2WBVx P {
    margin: 0; 
}

.Key_Key__2WBVx h6 {
    font-size: .6rem;
}

.Key_KeyItem__1WTik {
    opacity: 0.9;
	margin: 0;
    font-size: .75rem;
}

.Key_Box__1AhYr {
    vertical-align: middle;
    display: inline-block; 
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0;
}
.GradientKey_Key__3sxRt {
    position: absolute;
    /* font-size: .8rem; */
    bottom: 5px;
    left: 0;
    text-align: left;
    /* max-width: 160pxs */
    color: #096070;
}

.GradientKey_KeyDark__1Ypcp {
    color: white;
}

.GradientKey_Key__3sxRt P {
    margin: 5px; 
}

.GradientKey_Key__3sxRt h6 {
    font-size: .6rem;
}

.GradientKey_Bar__1ejtx {
    margin: 5px;
    height: 100px;
    width: 15px;
    left: 10px;
    bottom: 10px;
    /* background-image: linear-gradient(rgb(9, 96, 112), rgb(155, 219, 241)); */
}

.GradientKey_Label__2nKF6 {
    opacity: 0.9;
	margin: 0;
    font-size: .7rem;
}

.GradientKey_Red__16p8H {
    position: absolute;
    top: 18px;
    left: 20px;
}

.GradientKey_Green__3pKSh {
    position: absolute;
    bottom: 2px;
    left: 20px;
}
.Back_Back__2hEzd {
    position: relative;
    margin: auto;
}

@media (min-width: 500px) and (min-height: 400px) {
    .Back_Back__2hEzd {
        width: 300px;
        border-radius: 10px;
        border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    }
}

@media (min-width: 500px) and (min-height: 400px) {
    .Back_BackDark__17dLo {
        border: 1px solid rgb(255, 255, 255);
        box-shadow: 0 10px 10px rgba(255, 255, 255, 0.52);
    }
}
.Exercise_Exercise__ZyfpD {
    position: relative;
    border: 1px solid #46464680;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    margin: 10px auto;
    border-radius: 4px;
    display: block;
    width: 100%;
    text-align: center;
    height: 104px;
    line-height: 104px;
    background-color: white;
    color: #464646;
    font-weight: bold;
}

.Exercise_Exercise__ZyfpD:hover {
    cursor: pointer;
    background-color: rgba(210, 237, 255, 0.924);
}

.Exercise_TitleArea__OYNKv {
    position: absolute;
    display: table;
    width: 100%;
    height: 100%;
}

.Exercise_TitleArea__OYNKv h5 {
    /* letter-spacing: 1px; */
    /* margin: 10px 0; */
    /* line-height: 104px; */
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 60%;
    padding: 0 50px;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
}


.Exercises_Exercises__gimvw {
    width: 100%;
    height: 100%;
    margin: auto;
}

.Exercises_Title__Ze9ru {
	/* letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center; */
}

.Exercises_Subtitle__3pOwo {
    font-size: .9rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: center;
}

.Exercises_Label__p06q- {
    margin: 25px 0 0 0;
	letter-spacing: 1px;
    font-weight: bold;
    font-size: 1.1rem;
    text-align: center;
    opacity: .7;
}

.Exercises_Sublabel__2HEmc {
    font-size: .6rem;
    margin: 0 0 5px 0;
    padding: 10px;
    opacity: 0.8;
    text-align: center;
}
.FullBody_FullBody__13X8s {
    width: 85%;
    margin: auto;
    text-align: center;
    font-size: 1rem;
}

@media (min-width: 500px) and (min-height: 400px) {
    .FullBody_FullBody__13X8s {
        width: 300px;
    }
}

.FullBody_Title__bP2Oz {
    color: #096070;
}

.FullBody_TitleDark__34Ma1 {
    color: white;
}

.FullBody_Footnote__1u5Lj {
    font-size: .75rem;
    opacity: 0.6;
    text-align: center;
}

.FullBody_Subtitle__bBM8r {
	letter-spacing: 1px;
    font-weight: bold;
    font-size: 0.7rem;
    opacity: 0.6;
    /* text-align: left; */
    padding-top: 5px;
}
.Modal_Modal__1BKXd {
    position: fixed;
    z-index: 500;
    background-color: white;
    border-radius: 4px;
    width: 90%;
    /* border: 1px solid #ccc; */
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 5%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.Modal_ModalDark__3_Zo5 {
    background-color: #464646;
    color: white;
}

@media (min-width: 650px) {
    .Modal_Modal__1BKXd {
        width: 500px;
        left: calc(50% - 250px);
    }
}
.ExFormElement_ExFormElement__2FOzR {
    padding: 6px;
}

.ExFormElement_InputGroupAddon__2mZ8y {
    min-width:100px;
}

.ExFormElement_InputGroupAddon__2mZ8y span {
    width: 100%;
}

.ExFormElement_Invalid__3ZIuy {
    border: 1px solid red;
    border-radius: .3rem;
}

.ExFormElement_Label__1YarC {
    /* font-weight: bold; */
    font-size: .9rem;
    display: block;
    margin-bottom: 8px;
}
.NewExForm_NewExForm__4inlb {
    width: 100%;
}
.MuscleHistory_Subtitle__2KVpc {
    font-size: 1rem;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
    /* text-transform: uppercase; */
    margin: 0;
    opacity: 0.9;
    text-align: center;
}

.MuscleHistory_Delete__3s6pV {
    font-size: .9rem;
    margin: 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: center;
}

.MuscleHistory_Caption__1x_89 {
    font-size: .65rem;
    margin: 0 auto;
    opacity: 0.6;
    text-align: center;
}
.MuscleHistoryElement_MuscleHistoryElement__2yQLj {
    /* position: relative; */
    border: 1px solid #46464630;
    /* padding: 10px; */
    /* box-shadow: 2px 2px #464646; */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    margin: 10px auto;
    border-radius: 4px;
	display: flex;
	align-items: center;
    justify-content: center;
    background-color: white;
    color: #464646;
}

.MuscleHistoryElement_MuscleHistoryElement__2yQLj:hover {
    cursor: pointer;
    background-color: rgba(210, 237, 255, 0.897);
}

.MuscleHistoryElement_MuscleHistoryElement__2yQLj h6 {
    opacity: 0.8;
	padding: 2px;
    letter-spacing: 1px;
    font-size: .8rem;
    text-transform: uppercase;
}

.MuscleHistoryElement_Left__3Yzv4 {
    background-color:#096070;
    color: #fff;
    padding-top: 5px;
    min-width: 150px;
    border-radius: 4px 0 0 4px;
}

.MuscleHistoryElement_Left__3Yzv4 h5 {
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 2px;
    font-weight: bold;
    font-size: .8rem;
}

.MuscleHistoryElement_Right__2eh_l h5 {
    font-size: 1.0rem;
    /* text-transform: uppercase; */
}

.MuscleHistoryElement_LeftDark__3AyGN {
    background-color: #00c353;
}



.MuscleHistoryElement_Right__2eh_l {
	/* padding: 30px; */
	position: relative;
    width: 100%;
}

.MuscleHistoryElement_Right__2eh_l h5 {
	margin: 5px 0;
}
.CustomExForm_Label__2ge6o {
    margin: 25px 0 0 0;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
    text-align: left;
}

.CustomExForm_Sublabel__3qcMB {
    font-size: .75rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: left;
}

.CustomExForm_Muscles__38Kba {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.CustomExForm_Muscle__b-djN {
    
    padding: 5px;
    margin: 5px auto;
    background-color: darkslategray;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

/* .Muscle:hover {
    background-color: white;
    color: darkslategray;
    border: 1px solid darkslategray;
} */

.CustomExForm_MuscleSelected__1c1mu {
    background-color: darkmagenta;
    border: 1px solid white;
}
.InitialSettings_Settings__19Sv6 {
    /* width: 90%; */
    margin: auto;
    text-align: center;
    font-size: 1rem;
    /* padding: 30px; */
}

@media (min-width: 500px) and (min-height: 400px) {
    .InitialSettings_Settings__19Sv6 {
        width: 80%;
        /* border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px; */
    }
}


.InitialSettings_Label__1Ms9e {
    margin: 25px 0 0 0;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
    text-align: left;
}

.InitialSettings_Sublabel__18ZFv {
    font-size: .6rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: left;
}

.InitialSettings_Subtitle__NXflO {
    font-size: .7rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: center;
}

.InitialSettings_GenderSelected__3Kzj_ {
    background-color: #28a745;
    border-color: #28a745;
}

.InitialSettings_GenderSelected__3Kzj_:disabled {
    background-color: #28a745;
    border-color: #28a745;
    opacity: 100;
}


.ColourDemo_Demo__sAFDh {
    /* background-color: white; */
    padding-top: 15px;
    margin: auto;
    /* height: 150px; */
    display: flex;
    flex-flow: row;
}

.ColourDemo_Demo__sAFDh img {
    max-height: 250px;
    width: 50%;
}

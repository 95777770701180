.Settings {
    /* width: 90%; */
    margin: auto;
    text-align: center;
    font-size: 1rem;
    /* padding: 30px; */
}

@media (min-width: 500px) and (min-height: 400px) {
    .Settings {
        width: 80%;
        /* border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px; */
    }
}


.Label {
    margin: 25px 0 0 0;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
    text-align: left;
}

.Sublabel {
    font-size: .6rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: left;
}

.Subtitle {
    font-size: .7rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: center;
}

.GenderSelected {
    background-color: #28a745;
    border-color: #28a745;
}

.GenderSelected:disabled {
    background-color: #28a745;
    border-color: #28a745;
    opacity: 100;
}


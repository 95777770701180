.Key {
    position: absolute;
    /* font-size: .8rem; */
    left: 5px;
    bottom: 5px;
    text-align: left;
    /* max-width: 160pxs */
    color: #096070;
}

.KeyDark {
    color: white;
}

.Key P {
    margin: 0; 
}

.Key h6 {
    font-size: .6rem;
}

.KeyItem {
    opacity: 0.9;
	margin: 0;
    font-size: .75rem;
}

.Box {
    vertical-align: middle;
    display: inline-block; 
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0;
}
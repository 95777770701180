.ExFormElement {
    padding: 6px;
}

.InputGroupAddon {
    min-width:100px;
}

.InputGroupAddon span {
    width: 100%;
}

.Invalid {
    border: 1px solid red;
    border-radius: .3rem;
}

.Label {
    /* font-weight: bold; */
    font-size: .9rem;
    display: block;
    margin-bottom: 8px;
}
.FullBody {
    width: 85%;
    margin: auto;
    text-align: center;
    font-size: 1rem;
}

@media (min-width: 500px) and (min-height: 400px) {
    .FullBody {
        width: 300px;
    }
}

.Title {
    color: #096070;
}

.TitleDark {
    color: white;
}

.Footnote {
    font-size: .75rem;
    opacity: 0.6;
    text-align: center;
}

.Subtitle {
	letter-spacing: 1px;
    font-weight: bold;
    font-size: 0.7rem;
    opacity: 0.6;
    /* text-align: left; */
    padding-top: 5px;
}
.Pattern {
    height: 100%;
}

.Pattern path {
    fill: white;
    stroke-width: 90;
}

.DarkGreen {
    stroke: rgb(93, 151, 93);
}

.Green {
    stroke: rgb(144, 207, 147);
}

.Amber {
    stroke: rgb(240, 205, 50);
}

.DarkAmber {
    stroke: rgb(226, 134, 13);
}

.Red {
    stroke: rgb(156, 51, 51);
} 

.NonClickable {
    stroke: rgb(93, 151, 93);
}
.Key {
    position: absolute;
    /* font-size: .8rem; */
    bottom: 5px;
    left: 0;
    text-align: left;
    /* max-width: 160pxs */
    color: #096070;
}

.KeyDark {
    color: white;
}

.Key P {
    margin: 5px; 
}

.Key h6 {
    font-size: .6rem;
}

.Bar {
    margin: 5px;
    height: 100px;
    width: 15px;
    left: 10px;
    bottom: 10px;
    /* background-image: linear-gradient(rgb(9, 96, 112), rgb(155, 219, 241)); */
}

.Label {
    opacity: 0.9;
	margin: 0;
    font-size: .7rem;
}

.Red {
    position: absolute;
    top: 18px;
    left: 20px;
}

.Green {
    position: absolute;
    bottom: 2px;
    left: 20px;
}
.Label {
    margin: 25px 0 0 0;
	letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: .9rem;
    text-align: left;
}

.Sublabel {
    font-size: .75rem;
    margin: 0 0 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: left;
}

.Muscles {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
}

.Muscle {
    
    padding: 5px;
    margin: 5px auto;
    background-color: darkslategray;
    border: 1px solid rgb(255, 255, 255);
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

/* .Muscle:hover {
    background-color: white;
    color: darkslategray;
    border: 1px solid darkslategray;
} */

.MuscleSelected {
    background-color: darkmagenta;
    border: 1px solid white;
}
.AuthUI {
    width: 90%;
    margin: auto;
    padding: 20px 5px;
    text-align: center;
    /* font-weight: bold; */
    font-size: 1rem;
    align-items: center;

    /* background-color: white; */
}

@media (min-width: 500px) and (min-height: 400px) {
    .AuthUI {
        width: 300px;
        border: 1px solid #46464640;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
    }
}

.AuthUIDark {
    color: white;
}

@media (min-width: 500px) and (min-height: 400px) {
    .AuthUIDark {
        border: 1px solid rgb(255, 255, 255);
        box-shadow: 0 10px 10px #ffffff85;
    }
}
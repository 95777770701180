.Muscle {
    cursor: pointer;
    /* fill: grey; */
}

.Icon {
    fill: #464646;
    /* fill: white; */
} 

.IconSelected {
    fill: yellowgreen;
    /* fill: yellow; */
}
.Demo {
    /* background-color: white; */
    padding-top: 15px;
    margin: auto;
    /* height: 150px; */
    display: flex;
    flex-flow: row;
}

.Demo img {
    max-height: 250px;
    width: 50%;
}
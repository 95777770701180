.MuscleHistoryElement {
    /* position: relative; */
    border: 1px solid #46464630;
    /* padding: 10px; */
    /* box-shadow: 2px 2px #464646; */
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    margin: 10px auto;
    border-radius: 4px;
	display: flex;
	align-items: center;
    justify-content: center;
    background-color: white;
    color: #464646;
}

.MuscleHistoryElement:hover {
    cursor: pointer;
    background-color: rgba(210, 237, 255, 0.897);
}

.MuscleHistoryElement h6 {
    opacity: 0.8;
	padding: 2px;
    letter-spacing: 1px;
    font-size: .8rem;
    text-transform: uppercase;
}

.Left {
    background-color:#096070;
    color: #fff;
    padding-top: 5px;
    min-width: 150px;
    border-radius: 4px 0 0 4px;
}

.Left h5 {
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0 2px;
    font-weight: bold;
    font-size: .8rem;
}

.Right h5 {
    font-size: 1.0rem;
    /* text-transform: uppercase; */
}

.LeftDark {
    background-color: #00c353;
}



.Right {
	/* padding: 30px; */
	position: relative;
    width: 100%;
}

.Right h5 {
	margin: 5px 0;
}
@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');


body {
  margin: 0;
  padding: 0;
  /* background-color: #cee5e9; */
  /* background-color: lightgrey; */
  /* background-color: #464646; */
  /* background-color: white; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

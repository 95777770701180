.Alert {
    position: fixed;
    width: 100%;
    height: 40px;
    left: 0;
    top: 56px;
    z-index: 200;
    /* background-color: #d4edda;
    color: #0f4111; */
    background-color: #00c353;
    color: #fdfdfd;
    line-height: 40px;
    font-weight: bold;
    box-sizing: border-box;
    transition: transform 0.3s ease-out;
}

.Show {
    transform: translateY(0);
}

.Hide {
    transform: translateY(-120px);
}


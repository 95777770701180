.Subtitle {
    font-size: 1rem;
    /* font-weight: bold; */
    letter-spacing: 0.5px;
    /* text-transform: uppercase; */
    margin: 0;
    opacity: 0.9;
    text-align: center;
}

.Delete {
    font-size: .9rem;
    margin: 5px 0;
    padding-top: 0;
    opacity: 0.6;
    text-align: center;
}

.Caption {
    font-size: .65rem;
    margin: 0 auto;
    opacity: 0.6;
    text-align: center;
}